export function matchPath(fullPath: string, paths: string[]) {
  const sortedPaths = paths.slice().sort((a, b) => b.length - a.length)
  const lowerFullPath = fullPath.toLowerCase()
  for (let p of sortedPaths) {
    if (lowerFullPath.indexOf(p.toLowerCase()) === 0) {
      return p
    }
  }

  return null
}

export function matchWildcard(str: string, rule: string) {
  var escapeRegex = (str: string) => str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1')
  return new RegExp('^' + rule.split('*').map(escapeRegex).join('.*') + '$').test(str)
}
