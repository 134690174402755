import { LOCAL_STORAGE_KEY_RATE_LIMIT_URL_SEARCH_PARAMETER_OVERRIDE } from '../config'
import { IRateLimitURLSearchParameterOverride, PathConfig } from '../types'

/**
 * This will get rate limit overrides for services from URL search parameters
 * 1) if tag_launcher_rate_limit_override = 1
 *      will read override_services=serviceA,ServiceB,ServiceC
 *      and  read override_rate_limits=100,50,30
 *      this will result in override the rate limits for those services:
 *          - serviceA=100
 *          - ServiceB=50
 *          - ServiceC=30
 *      to set rate limit for all services specified, use override_all_rate_limit=100
 *      we will save the override to localStorage
 * 2) if tag_launcher_rate_limit_override = 0
 *      will empty the localStorage for previous override setting
 *      will empty the rate limit override
 * 3) no tag_launcher_rate_limit_override set in URL
 *      will read override from localStorage if exists
 */
export function getRateLimitURLParameterOverride(
  currentPageURL?: string
): IRateLimitURLSearchParameterOverride {
  const currentURL = new URL(currentPageURL || location.href)
  const rateLimitOverride = currentURL.searchParams.get('tag_launcher_rate_limit_override')
  if (rateLimitOverride === '0') {
    const emptyOverride = {
      enabled: false,
      rateLimits: {},
    }
    updateLocalRateLimitOverride(emptyOverride)
    return emptyOverride
  } else if (rateLimitOverride === '1') {
    const services = (currentURL.searchParams.get('override_services') || '').split(',')
    const rateLimits = (currentURL.searchParams.get('override_rate_limits') || '').split(',')
    const allRateLimitOverride = currentURL.searchParams.get('override_all_rate_limit')
    let allRateLimit = 0
    let enableAllRateLimitOverride = false
    if (
      allRateLimitOverride !== undefined &&
      allRateLimitOverride !== '' &&
      allRateLimitOverride !== null
    ) {
      allRateLimit = parseFloat(allRateLimitOverride)
      if (allRateLimit >= 0 && allRateLimit <= 100) {
        enableAllRateLimitOverride = true
      }
    }
    const newOverride = {
      enabled: true,
      rateLimits: services.reduce(
        (override: Record<string, number>, service: string, index: number) => {
          if (enableAllRateLimitOverride) {
            return {
              ...override,
              [service]: allRateLimit,
            }
          }
          const rateLimit = rateLimits[index]
          if (rateLimit !== undefined && rateLimit !== '') {
            const rate = parseFloat(rateLimit)
            if (rate <= 100 && rate >= 0) {
              return {
                ...override,
                [service]: parseFloat(rateLimit),
              }
            }
          }
          return override
        },
        {}
      ),
    }
    updateLocalRateLimitOverride(newOverride)
    return newOverride
  }
  return getLocalRateLimitOverride()
}

export function getLocalRateLimitOverride(): IRateLimitURLSearchParameterOverride {
  const localOverride = localStorage.getItem(
    LOCAL_STORAGE_KEY_RATE_LIMIT_URL_SEARCH_PARAMETER_OVERRIDE
  )
  if (localOverride) {
    try {
      const data =
        (JSON.parse(localOverride) as Partial<IRateLimitURLSearchParameterOverride>) || {}
      return {
        enabled: data.enabled || false,
        rateLimits: data.rateLimits || {},
      }
    } catch (err) {
      console.error('Narratiive Tag Launcher local rate limit override read error', err)
    }
  }
  return {
    enabled: false,
    rateLimits: {},
  }
}

export function updateLocalRateLimitOverride(override: IRateLimitURLSearchParameterOverride): void {
  try {
    localStorage.setItem(
      LOCAL_STORAGE_KEY_RATE_LIMIT_URL_SEARCH_PARAMETER_OVERRIDE,
      JSON.stringify(override)
    )
  } catch (err) {
    console.error('Narratiive Tag Launcher local rate limit override save error', err)
  }
}

export function updateHostConfigWithRateLimitURLSearchParameterOverride(
  hostConfig: Record<string, PathConfig>,
  rateLimitOverride: IRateLimitURLSearchParameterOverride
): Record<string, PathConfig> {
  if (rateLimitOverride.enabled) {
    const rateLimits = rateLimitOverride.rateLimits
    const originHostInfo = hostConfig || {}
    const newHostInfo: Record<string, PathConfig> = {}
    Object.keys(originHostInfo).forEach(function (pathKey) {
      newHostInfo[pathKey] = {
        ...originHostInfo[pathKey],
        services: (originHostInfo[pathKey].services || []).map(function (service) {
          if (rateLimits[service.key] !== undefined) {
            return {
              ...service,
              rateLimit: rateLimits[service.key],
            }
          }
          return service
        }),
      }
    })
    return newHostInfo
  }
  return hostConfig
}
