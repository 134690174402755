// https://github.com/covalercom/narratiive-taglauncher-server/blob/main/src/types/index.ts
import Launcher from './classes/launcher'

export interface ServiceConfig {
  key: string
  rateLimit?: number
  scriptURL?: string
  dependsOn?: string
  options?: Record<string, any>
}

export type IFeatureFlagConfigValue = string | number | boolean | { rateLimit: number } | { values: any[]; rateLimits: number[] }

export type IFeatureFlagConfig = Record<
  string,
  IFeatureFlagConfigValue
>

export interface PathConfig {
  publisherId?: string
  publicationId?: string
  publisherName?: string
  publicationName?: string
  services?: ServiceConfig[]
  featureFlags?: IFeatureFlagConfig
}

export interface ClientInfo {
  country?: string
  region?: string
  city?: string
  android?: '1' | '0'
  ios?: '1' | '0'
  tv?: '1' | '0'
  tablet?: '1' | '0'
  desktop?: '1' | '0'
  mobile?: '1' | '0'
  ua?: string
}

export interface PageInfo {
  publisherId?: string
  publicationId?: string
  publisherName?: string
  publicationName?: string
  hostname?: string
  pageURL?: string
}

export interface LaunchConfig {
  hostConfig: Record<string, PathConfig>
  clientInfo: ClientInfo
}

export interface UserInfo {
  uid?: string
  optout?: boolean //todo or gdrp/TCF
}

export interface LauncherOption {
  hostname?: string
  pageURL?: string
  autoLaunch?: boolean
  hostConfig?: Record<string, PathConfig> | ((hostConfig: Record<string, PathConfig>) => Record<string, PathConfig>)
  debug?: boolean
  callback?: (launcher: Launcher) => void
}

export interface IRateLimitURLSearchParameterOverride {
  enabled: boolean;
  // will be the serviceKey: rateLimit pairs
  rateLimits: Record<string, number>;
}

export interface ITagLauncherMetaData {
  clientInfo?: ClientInfo
  pageInfo?: PageInfo
  userInfo: UserInfo
  featureFlags?: Record<string, any>
}

export interface ITagLauncherPaddedEventData {
  data: any
  meta: ITagLauncherMetaData
}

export enum ETagLauncherEvent {
  initialized = 'init',
  launchInfoLoaded = 'launchInfoLoaded',
}
