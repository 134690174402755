import { ECOUNTER_HOST, IS_TESTING, IS_PRODUCTION } from '../config'
import { ITagLauncherPaddedEventData } from '../types'
import { sub, unsub } from '../utils/pubsub'

export const ecounterEventEndpoint = `${ECOUNTER_HOST}/event`
export const ecounterErrorEndpoint = `${ECOUNTER_HOST}/error`

export default class ECounter {
  eventPatterns: string[]
  playback: boolean

  constructor(eventPatterns: string[], playback = true) {
    this.eventPatterns = eventPatterns || []
    this.playback = playback
  }

  sendReport = (pixelURL: string): void => {
    if (IS_TESTING) {
      // msw can not capture image request so for testing we will manually request
      const xhr = new XMLHttpRequest()
      xhr.open('GET', pixelURL, true)
      xhr.send()
    } else if (IS_PRODUCTION) {
      const img = document.createElement('img')
      img.src = pixelURL
    } else {
      console.log('==== Event Counter report ====')
      console.log(pixelURL)
    }
  }

  reportEvent = (
    eventName: string,
    eventData: Record<string, string | number>,
    metaData: Record<string, string | number>,
  ): void => {
    const payload = encodeURIComponent(JSON.stringify({ eventName, eventData, metaData }))
    const cacheBuster = Date.now()
    const pixelUrl = `${ecounterEventEndpoint}?event=${payload}&cb=${cacheBuster}`
    this.sendReport(pixelUrl)
  }

  reportError = (errorName: string, eventData: Record<string, string | number>, metaData: Record<string, string | number>): void => {
    const payload = encodeURIComponent(JSON.stringify({ eventData, metaData }))
    const cacheBuster = Date.now()
    const pixelUrl = `${ecounterErrorEndpoint}?err=${errorName}&meta=${payload}&cb=${cacheBuster}`
    this.sendReport(pixelUrl)
  }

  eventHandler = (eventName: string, eventData: Record<string, any> | ITagLauncherPaddedEventData): void => {
    let metaData, data
    if ('meta' in eventData) {
      metaData = eventData.meta
      data = eventData.data
    } else {
      data = eventData
    }
    if (eventName.includes(':error')) {
      this.reportError(eventName, data, metaData)
    } else {
      this.reportEvent(eventName, data, metaData)
    }
  }

  unsubscribe = (): void => {
    if (this.eventPatterns?.length) {
      this.eventPatterns.forEach(eventPattern => {
        unsub(eventPattern, this.eventHandler)
      })
    }
  }

  subscribe = (): void => {
    this.unsubscribe()
    if (ECOUNTER_HOST) {
      if (this.eventPatterns?.length) {
        this.eventPatterns.forEach(eventPattern => {
          sub(eventPattern, this.eventHandler, this.playback)
        })
      }
    } else {
      console.error('Narratiive Event Counter host is not configured, the logs will not be reported')
    }
  }
} 
