import {
  ClientInfo,
  ETagLauncherEvent,
  ITagLauncherMetaData,
  LaunchConfig,
  LauncherOption,
  PageInfo,
  ServiceConfig,
  UserInfo,
} from '../types'
import { initIdCookie } from '../utils/idCookie'
import { matchPath } from '../utils/string'
import Service from './service'
import { ID_COOKIE_NAME, LAUCH_SCRIPT_BASE, TAG_LAUNCHER_EVENT_PREFIX } from '../config'
import { pub, sub, PubSubCallback } from '../utils/pubsub'
import { loadScript } from '../utils/dom'
import { getReverseDomain } from '../utils/domain'
import {
  getRateLimitURLParameterOverride,
  updateHostConfigWithRateLimitURLSearchParameterOverride
} from './rateLimitURLSearchParameterOverride'
import FeatureFlagDecider from './featureFlagDecider'

export default class Launcher {
  private serviceConfigs: ServiceConfig[] = []
  private clientInfo: ClientInfo
  private pageInfo: PageInfo
  private userInfo?: UserInfo
  private featureFlags?: Record<string, any>
  private option: LauncherOption

  static getTagLauncherEventNameWithPrefix = (eventName: ETagLauncherEvent): string => {
    return `${TAG_LAUNCHER_EVENT_PREFIX}:${eventName}`
  }

  constructor(option?: LauncherOption) {
    this.option = {
      autoLaunch: true,
      hostname: window.location.hostname,
      pageURL: window.location.href,
      debug: window.localStorage.getItem('debug') === '1',
      ...option,
    }
    this.userInfo = {
      uid: initIdCookie(ID_COOKIE_NAME),
    }
    this.publishTabLauncherEvent(ETagLauncherEvent.initialized)
  }

  // after we load launch config, we will perform severl level of override
  // the order:
  // 1) the `hostConfig` that is passed to tag Launcher directly 
  // 2) any override from the current page URL (and the saved override in localStorage)
  private processLaunchConfig(config?: LaunchConfig) {
    this.clientInfo = { ...config?.clientInfo, ua: navigator.userAgent }
    let hostConfig = config?.hostConfig
    const hostConfigOverride = this.option.hostConfig
    if (hostConfigOverride) {
      if (typeof hostConfigOverride == 'function') {
        hostConfig = hostConfigOverride(hostConfig)
      } else {
        hostConfig = hostConfigOverride
      }
    }

    hostConfig = updateHostConfigWithRateLimitURLSearchParameterOverride(
      hostConfig, getRateLimitURLParameterOverride()
    )

    if (hostConfig) {
      const currentPath = window.location.pathname
      const paths = Object.keys(hostConfig)
      const matchedPath = matchPath(currentPath, paths)
      const pathConfig = (matchedPath && hostConfig[matchedPath]) || undefined
      this.pageInfo = {
        publicationId: pathConfig?.publicationId,
        publisherId: pathConfig?.publisherId,
        publisherName: pathConfig?.publisherName,
        publicationName: pathConfig?.publicationName,
        hostname: this.option.hostname,
        pageURL: this.option.pageURL,
      }
      this.serviceConfigs = pathConfig?.services || []

      const featureFlagConfig = pathConfig?.featureFlags
      if (featureFlagConfig) {
        this.featureFlags = (new FeatureFlagDecider(featureFlagConfig)).getFeatureFlags()
      }
    }
  }

  private logMsg(msg: string, data?: any) {
    if (this.option.debug) {
      console.info(`[Tag Launcher] ${msg}`, data)
    }
  }

  publishTabLauncherEvent(eventName: ETagLauncherEvent, eventData?: Record<string, any>): void {
    this.publishEvent(Launcher.getTagLauncherEventNameWithPrefix(eventName), eventData)
  }

  publishEvent(eventName: string, eventData: any | null | undefined = null): void {
    const paddedEventData = { data: eventData, meta: this.getMetaData() }
    this.logMsg(`Event: ${eventName}`, paddedEventData)
    pub(eventName, paddedEventData)
  }

  subscribeEvent(eventPattern: string, callback: PubSubCallback, playback: boolean): void {
    const wrapCallback = (eventName: string, paddedEventData: any) => {
      const { data, meta } = paddedEventData
      callback.call(null, eventName, data, meta)
    }
    sub(eventPattern, wrapCallback, playback)
  }

  getMetaData(): ITagLauncherMetaData {
    if (this.clientInfo && this.pageInfo) {
      return {
        clientInfo: this.clientInfo,
        pageInfo: this.pageInfo,
        userInfo: this.userInfo,
        featureFlags: this.featureFlags,
      }
    }
    return {
      userInfo: this.userInfo,
    }
  }

  start(): void {
    this.logMsg('start()')
    const reverseHost = getReverseDomain(this.option.hostname)
    const lanchScript = `${LAUCH_SCRIPT_BASE}/with/${reverseHost}/launch.js`
    loadScript(lanchScript)
  }

  launch(config: LaunchConfig): void {
    this.logMsg('launch()')
    this.processLaunchConfig(config)

    if (this.option.autoLaunch) {
      // todo this is temporary, remove once we get rid of all the ecounter config
      // filter out event counter as now it is builtin with tag launcher
      const servicesToLaunch = (this.serviceConfigs || []).filter(item => item.key !== 'pressevent')
      Service.launchServices(servicesToLaunch)
    }

    if (this.option.callback) {
      this.option.callback(this)
    }
    this.publishTabLauncherEvent(ETagLauncherEvent.launchInfoLoaded)
  }

  launchServiceByKey(serviceKey: string, testEligibility = false): Service {
    const foundConfigs = this.serviceConfigs.filter(config => config.key === serviceKey)
    if (foundConfigs.length > 0) {
      return Service.launchService(foundConfigs[0], testEligibility)
    }

    return null
  }
}
