export function loadScript(src: string, scriptOption?: Record<string, any>) {
  const scriptElm = document.getElementsByTagName('script')[0]
  const elm = document.createElement('script') as HTMLScriptElement
  if (scriptOption) {
    // embed service specific option on the script, so the javascript can read the option itself
    elm.setAttribute('data-option', encodeURIComponent(JSON.stringify(scriptOption)))
  }
  elm.async = true
  elm.src = src
  return scriptElm?.parentNode?.insertBefore(elm, scriptElm)
}
