import { ServiceConfig } from '../types'
import { loadScript } from '../utils/dom'
import RateLimiter from './rateLimiter'

export default class Service {
  config: ServiceConfig

  constructor(config: ServiceConfig) {
    this.config = config
  }

  isEligible(): boolean {
    if (this.config.rateLimit === 100) {
      return true
    } else if (this.config.rateLimit === 0 || this.config.rateLimit === undefined) {
      return false
    } else {
      const rateLimiter = new RateLimiter(
        this.config.key,
        [true, false],
        [this.config.rateLimit, 100 - this.config.rateLimit]
      )
      return rateLimiter.makeDecision().value
    }
  }

  launch(): Service {
    loadScript(this.config.scriptURL, this.config.options)
    return this
  }

  static launchServices(serviceConfigs: ServiceConfig[], parentService?: Service): Service[] {
    const parentKey = parentService?.config?.key
    const eligibleConfigs = serviceConfigs.filter(s => s.dependsOn === parentKey)
    const launchedServices: Service[] = eligibleConfigs
      .map(config => new Service(config))
      .filter(s => s.isEligible())
      .map(service => service.launch())

    const subServices: Service[][] = launchedServices
      .map(s => Service.launchServices(serviceConfigs, s))
      .filter(i => i.length > 0)

    return launchedServices.concat(...subServices)
  }

  static launchService(config: ServiceConfig, testEligibility = false): Service {
    const service = new Service(config)

    if (!testEligibility || service.isEligible()) {
      return service.launch()
    }

    return null
  }
}
