import Launcher from './classes/launcher'
import { GLOBAL_OPTION_VAR, GLOBAL_VAR, ECOUNTER_EVENT_PATTERNS } from './config'
import ECounter from './classes/ecounter'

// initialize event counter
const ecounter = new ECounter(ECOUNTER_EVENT_PATTERNS)
ecounter.subscribe()

const customOption = (window as any)[GLOBAL_OPTION_VAR] || {}
const launcher = new Launcher(customOption)

;(window as any)[GLOBAL_VAR] = launcher

launcher.start()
