interface CookieOption {
  days?: number
  path?: string
  domain?: string
  secure?: boolean
}

export const setCookie = (name: string, value: string, option: CookieOption) => {
  const { days, path = '/', domain, secure } = option

  let cookie = `${name}=${encodeURIComponent(value)}`

  // Add expiry date
  if (days) {
    const expiry = new Date()
    expiry.setDate(expiry.getDate() + days)
    cookie += `; expires=${expiry.toUTCString()}`
  }

  // Add Path, Domain, and Secure
  if (path) cookie += `; path=${path}`
  if (domain) cookie += `; domain=${domain}`
  if (secure) cookie += `; secure`

  // Set an HTTP cookie
  document.cookie = cookie
}

export const getCookie = (name: string) => {
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    let c = cookies[i].trim().split('=')
    if (c[0] === name) {
      return decodeURIComponent(c[1])
    }
  }
  return undefined
}
