import { matchWildcard } from './string'

export type PubSubCallback = (eventName: string, eventData: any | null | undefined) => void
type PubSubQueueItem = { eventName: string; eventData: any | null }
const callbackMap: Record<string, PubSubCallback[]> = {}
const pubQueue: PubSubQueueItem[] = []

const matchEventName = (eventName: string, eventPattern: string) => {
  const lowerName = eventName.toLowerCase()
  const lowerPattern = eventPattern.toLowerCase()
  return lowerName === lowerPattern || matchWildcard(lowerName, lowerPattern)
}

const findCallbacksForEvent = (eventName: string) => {
  let result: any[] = []
  for (const eventPattern in callbackMap) {
    if (matchEventName(eventName, eventPattern)) {
      const callbacks = callbackMap[eventPattern] || []
      result = result.concat(callbacks)
    }
  }
  return result
}

export const pub = (eventName: string, eventData: any | null | undefined = undefined): void => {
  if (/^\w+:[\w-]+$/.test(eventName)) {
    const callbacks = findCallbacksForEvent(eventName)
    callbacks.forEach(cb => {
      cb.call(null, eventName, eventData)
    })
    pubQueue.push({ eventName, eventData })
  } else {
    throw new Error('Event name must be in the format of "[service]:[name]"')
  }
}

export const sub = (eventPattern: string, callback: PubSubCallback, playback = false): void => {
  if (callbackMap[eventPattern]) {
    callbackMap[eventPattern].push(callback)
  } else {
    callbackMap[eventPattern] = [callback]
  }

  // playback event
  if (playback) {
    pubQueue.forEach(i => {
      if (matchEventName(i.eventName, eventPattern)) {
        callback.call(null, i.eventName, i.eventData)
      }
    })
  }
}

export const unsub = (eventPattern: string, callback: PubSubCallback): void => {
  if (callbackMap[eventPattern]) {
    callbackMap[eventPattern] = callbackMap[eventPattern].filter(cb => cb !== callback)
  }
}

export default { pub, sub }
