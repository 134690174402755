import { IFeatureFlagConfig, IFeatureFlagConfigValue } from '../types'
import RateLimiter from './rateLimiter'

export default class FeatureFlagDecider {
  config: IFeatureFlagConfig
  options?: {
    storeRateDecisionInMemoryOnly?: boolean
  }
  rateLimiters: Record<string, RateLimiter>

  constructor(
    config: IFeatureFlagConfig,
    options: { storeRateDecisionInMemoryOnly?: boolean } = {}
  ) {
    this.config = config
    this.options = options
    this.rateLimiters = {}
  }

  getFeatureFlagRateLimiterKey(featureFlagKey: string): string {
    return `ff_${featureFlagKey}`
  }

  getFeatureFlags(): Record<string, any> {
    const featureFlags: Record<string, any> = {}
    Object.keys(this.config).forEach(featureFlagKey => {
      const featureConfig = this.config[featureFlagKey] as IFeatureFlagConfigValue
      if (featureConfig !== undefined) {
        if (typeof featureConfig === 'object') {
          let values: any[]
          let rateLimits: number[]
          if ('rateLimit' in featureConfig) {
            values = [true, false]
            rateLimits = [featureConfig.rateLimit, 100 - featureConfig.rateLimit]
          } else if ('values' in featureConfig && 'rateLimits' in featureConfig) {
            values = featureConfig.values
            rateLimits = featureConfig.rateLimits
          }
          if (values.length) {
            let rateLimiter: RateLimiter
            if (featureFlagKey in this.rateLimiters) {
              rateLimiter = this.rateLimiters[featureFlagKey]
            } else {
              rateLimiter = new RateLimiter(
                this.getFeatureFlagRateLimiterKey(featureFlagKey),
                values,
                rateLimits,
                this.options
              )
              this.rateLimiters[featureFlagKey] = rateLimiter
            }
            featureFlags[featureFlagKey] = rateLimiter.makeDecision()?.value
          }
        } else {
          featureFlags[featureFlagKey] = featureConfig
        }
      }
    })
    return featureFlags
  }
}
