// chanceInOneHundred a number between 0 - 100
export const randomSelect = (chanceInOneHundred: number): boolean => {
  if (chanceInOneHundred === 0) {
    return false
  } else if (chanceInOneHundred === 100) {
    return true
  }
  return Math.random() * 100 < chanceInOneHundred
}

export const checkAndUpdateRateValuesAndRateLimits = (values: any[], rateLimists: number[]): {
  values: any[];
  rateLimists: number[];
} => {
  const totalRateLimit = rateLimists.reduce((total, item) => (total + item), 0)
  if (totalRateLimit > 100) {
    throw new Error('The rate limit sum for multiple value random select can not be greater than 100')
  } else if (values.length !== rateLimists.length) {
    throw new Error('The values much match with the rate limits for multiple value random select')
  }
  const valueCandidates = [...values]
  const rateLimistList = [...rateLimists]
  // we will ignore gap that is smaller than or equal to 0.01, for example [33.333, 66.666]
  // otherwise we will fill in the gap with null value
  if (Math.abs(totalRateLimit - 100) > 0.01) {
    valueCandidates.push(null)
    rateLimistList.push(100 - totalRateLimit)
  }
  return {
    values: valueCandidates,
    rateLimists: rateLimistList, 
  }
}

export const randomSelectMultipleValues = (values: any[], rateLimists: number[]): {
  value: any;
  rateLimit: number;
} => {
  const {
    values: valueCandidates,
    rateLimists: rateLimistList,
  } = checkAndUpdateRateValuesAndRateLimits(values, rateLimists)
  const totalCandidates = valueCandidates.length
  let index = 0
  let selectedIndex = null
  while (selectedIndex === null && index < totalCandidates) {
    const isLast = (index === (totalCandidates - 1))
    if (isLast) {
      selectedIndex = index
    } else {
      const actualRate = 100 * rateLimistList[index] / rateLimistList.slice(index).reduce((sum, item) => (sum + item), 0)
      const selected = randomSelect(actualRate)
      if (selected) {
        selectedIndex = index
      }
    }
    index++
  }
  return {
    value: valueCandidates[selectedIndex],
    rateLimit: rateLimistList[selectedIndex],
  }
}
