import { checkAndUpdateRateValuesAndRateLimits, randomSelect, randomSelectMultipleValues } from '../utils/random'
export default class RateLimiter {
  rateKey: string
  rateLimits: number[]
  rateValues: any[]
  // the following two are for testing purpose
  rateDecision?: { value: any; rateLimit: number; values: any[]; rateLimits: number[] }
  storeRateDecisionInMemoryOnly?: boolean

  constructor(
    rateKey: string,
    rateValues: any[],
    rateLimits: number[],
    options: { storeRateDecisionInMemoryOnly?: boolean } = {}
  ) {
    this.rateKey = rateKey
    // standardlized the rate limits and values
    // 1. fill the gap if the sum of the total rate limits are less then 100
    // 2. check if the total sum exceeds 100 or the values do not match with the rate limits
    const validatedRateInfo = checkAndUpdateRateValuesAndRateLimits(rateValues, rateLimits)
    this.rateLimits = validatedRateInfo.rateLimists
    this.rateValues = validatedRateInfo.values
    if (options) {
      this.storeRateDecisionInMemoryOnly = options.storeRateDecisionInMemoryOnly
    }
  }

  getLocalStorageKey(): string {
    return `narratiive_tl_rl_${this.rateKey}`
  }

  getRateDecision(): { value: any; rateLimit: number; values: any[]; rateLimits: number[] } {
    if (this.storeRateDecisionInMemoryOnly) {
      return this.rateDecision
    } else {
      const lsKey = this.getLocalStorageKey()
      let decision = localStorage.getItem(lsKey)
      // compatible with the old version
      if (decision && decision.indexOf('@') > 0) {
        const [selected, rate] = decision.split('@')
        const rateLimit = parseFloat(rate)
        if (!isNaN(rateLimit) && (selected === '0' || selected === '1')) {
          decision = JSON.stringify({
            value: selected === '1',
            rateLimit: selected === '1' ? rateLimit : 100 - rateLimit,
            values: [true, false],
            rateLimits: [rateLimit, 100 - rateLimit],
          })
        }
      }
      if (decision) {
        try {
          const decisionObj = JSON.parse(decision)
          if (decisionObj.rateLimit && decisionObj.rateLimit > 0) {
            return {
              value: decisionObj.value,
              rateLimit: parseFloat(decisionObj.rateLimit),
              values: decisionObj.values,
              rateLimits: decisionObj.rateLimits,
            }
          } else {
            console.error(`Parse Tag Launcher saved rate limit error for ${this.rateKey}`, 'rate is invalid')
          }
        } catch (error) {
          console.error(`Parse Tag Launcher saved rate limit error for ${this.rateKey}`, error)
        }
      }
    }
    return null
  }

  setRateLimits(newRateLimits: number[]): void {
    this.rateLimits = newRateLimits
  }

  setRateValues(rateValues: any[]): void {
    this.rateValues = rateValues
  }

  setRateDecision(value: unknown, rate: number): RateLimiter {
    if (this.storeRateDecisionInMemoryOnly) {
      this.rateDecision = {
        value,
        rateLimit: rate,
        values: this.rateValues,
        rateLimits: this.rateLimits,
      }
    } else {
      const lsKey = this.getLocalStorageKey()
      const lsValue = JSON.stringify({
        value,
        rateLimit: rate,
        values: this.rateValues,
        rateLimits: this.rateLimits,
      })
      localStorage.setItem(lsKey, lsValue)
    }
    return this
  }

  makeDecision(): { value: any; rateLimit: number } {
    // this is to get old decision (about show the service or not) saved from the localStorage
    const existingDecision = this.getRateDecision()
    let decision = existingDecision && { value: existingDecision?.value, rateLimit: existingDecision.rateLimit }
    const latestRateLimits = this.rateLimits
    const latestRateValues = this.rateValues
    const existingIndex = latestRateValues.indexOf(existingDecision?.value)
    const oldValues = existingDecision?.values || []

    // if values changed we will basically re make all decisions
    const valuesChanged = (
      oldValues.length !== latestRateValues.length
      || latestRateValues.filter(item => !oldValues.includes(item)).length > 0
    )
    // if decision has been made before
    if (!valuesChanged && existingDecision && latestRateValues.includes(existingDecision?.value)) {
      const { value: existingValue, rateLimit: existingRateLimit } = existingDecision
      const latestRateLimit = latestRateLimits[existingIndex]
      // compare the rate limited used for the existing decision and the new rate limit
      const isReducing = existingRateLimit > latestRateLimit

      // if the rate limit is reducing: we will need to remove some of the previously selected users
      // so we only focus on the previously selected user
      // so only the new_rate / old_rate percentage of the previously selected user should stay selected
      // example:
      // 1) before, rate limit 50, 100 people, we will have 50 people selected
      // 2) now, rate limit reduced to 40, same group of people, we expect the selected people to drop to 40
      //    so the existing 50 x 40/50 = 50 * 80% = 40
      if (isReducing) {
        const maintainSelected = randomSelect((latestRateLimit * 100) / existingRateLimit)
        if (maintainSelected) {
          decision = {
            value: existingValue,
            rateLimit: latestRateLimit,
          }
        } else {
          const restValues = latestRateValues.filter(item => item !== existingValue)
          const restRateLimits = [...latestRateLimits]
          restRateLimits.splice(existingIndex, 1)
          const restRateLimitsSum = restRateLimits.reduce((sum, item) => (sum + item), 0)
          const restRateLimitsUpdate = restRateLimits.map(item  => 100 * item / restRateLimitsSum)
          const updatedDecision = randomSelectMultipleValues(restValues, restRateLimitsUpdate)
          decision = {
            value: updatedDecision.value,
            rateLimit: restRateLimits[restRateLimitsUpdate.indexOf(updatedDecision.rateLimit)],
          }
        }
      } else {
        // if the rate limit is equal or increasing, we still need to update the cache
        decision = {
          value: existingValue,
          rateLimit: latestRateLimit,
        }
      }
    } else {
      decision = randomSelectMultipleValues(latestRateValues, latestRateLimits)
    }
    this.setRateDecision(decision.value, decision.rateLimit)
    return decision
  }
}
