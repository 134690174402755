const crcTable = (() => {
  let c
  const result = []
  for (let n = 0; n < 256; n += 1) {
    c = n
    for (let k = 0; k < 8; k += 1) {
      c = c & 1 ? 0xedb88320 ^ (c >>> 1) : c >>> 1
    }
    result[n] = c
  }
  return result
})()

export default function crc32(str: string) {
  let crc = 0 ^ -1

  for (let i = 0; i < str.length; i += 1) {
    crc = (crc >>> 8) ^ crcTable[(crc ^ str.charCodeAt(i)) & 0xff]
  }

  return (crc ^ -1) >>> 0
}
