
export const IS_TESTING = process.env.NODE_ENV === 'test'
export const IS_PRODUCTION = process.env.NODE_ENV === 'production'

export const GLOBAL_VAR = 'narratiiveTagLauncher'
export const GLOBAL_OPTION_VAR = 'narratiiveTagLauncherOption'
export const LAUCH_SCRIPT_BASE = 'https://tlserver-cdn.harkle.io'
export const ID_COOKIE_NAME = 'narratiive-tlt-uid'
export const LOCAL_STORAGE_KEY_RATE_LIMIT_URL_SEARCH_PARAMETER_OVERRIDE = 'NAR_TL_RATE_LIMIT_URL_SEARCH_PARAMETER_OVERRIDE'
export const ECOUNTER_HOST = process.env.ECOUNTER_HOST || 'https://event.staging.harkle.harkle.io'

export const TAG_LAUNCHER_EVENT_PREFIX  = 'tagLauncher'
export const HARKLE_EVENT_PREFIX  = 'harkle'
export const ECOUNTER_EVENT_PATTERNS = [
  `${TAG_LAUNCHER_EVENT_PREFIX}:*`,
  `${HARKLE_EVENT_PREFIX}:*`
]
