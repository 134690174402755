import { setCookie, getCookie } from './cookie'

function testSetCookieWithDomain(domain: string) {
  setCookie('foo', 'bar', { domain })
  const pass = getCookie('foo') === 'bar'
  setCookie('foo', '', { domain, days: -1 })
  return pass
}

function getRootDomainForCookies() {
  const hostname = window.location && window.location.hostname
  const hostParts = hostname ? hostname.split('.') : []

  for (let index = 2; index <= hostParts.length; index += 1) {
    const candidate = `.${hostParts.slice(-1 * index).join('.')}`
    if (testSetCookieWithDomain(candidate)) {
      return candidate
    }
  }

  return hostname
}

export function getReverseDomain(hostname: string) {
  return hostname.split('.').reverse().join('.')
}

export const rootDomain = getRootDomainForCookies()
