import { setCookie, getCookie } from './cookie'
import { rootDomain } from './domain'
import uuidv4 from './uuid'
import rcr32 from './rcr32'

export function genChecksum(value: string): string {
  const checksum = rcr32(value).toString(16)
  return `00000000${checksum}`.slice(-8) // 8 characters
}

function parseIdCookie(cookieValue: string) {
  if (cookieValue.length !== 45) {
    return false
  }
  const realId = cookieValue.substr(0, 36)
  const checksum = cookieValue.substr(-8)

  return genChecksum(realId) === checksum ? realId : undefined
}

function setIdToCookie(cookieName: string, id: string) {
  const checksum = genChecksum(id)
  setCookie(cookieName, `${id}-${checksum}`, { domain: rootDomain, days: 365 })
}

export function getIdFromCookie(cookieName: string): string {
  const cookieValue = getCookie(cookieName)
  return (cookieValue && parseIdCookie(cookieValue)) || undefined
}

export function initIdCookie(cookieName: string): string {
  const currentURL = new URL(location.href)
  const idCookieOverride = currentURL.searchParams.get(cookieName)
  const id = idCookieOverride || getIdFromCookie(cookieName) || uuidv4()
  setIdToCookie(cookieName, id)
  return id
}
